import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { InformacoesContainer } from './Informacoes.styles';
import { useAuth } from '../../AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Informacoes() {
    const location = useLocation();
    const codFarmacia = location.state?.codFarmacia;
    const [protocolNumber, setProtocolNumber] = useState('');
    const [protocolId, setProtocolId] = useState('');
    const [protocolStatus, setProtocolStatus] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientDocument, setClientDocument] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [labelName, setLabelName] = useState('');
    const [labelDocument, setLabelDocument] = useState('');
    const navigate = useNavigate();

    const { currentStep, setCurrentStep } = useAuth();

    useEffect(() => {
        if (currentStep < 0) {
            navigate('/login');
        } else {
            setCurrentStep(1);
        }
    }, [currentStep, setCurrentStep, navigate]);

    const handleProtocolNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Permite apenas dígitos
            setProtocolNumber(value);
        }
    };

    const handleProtocolNumberBlur = () => {
        if (protocolNumber) {
            setIsLoading(true);
            axios.get(`${API_BASE_URL}/api/protocol/${protocolNumber}`, { withCredentials: true })
                .then(response => {
                    const protocolData = response.data[0];
                    setProtocolStatus(protocolData.status);
                    setProtocolId(protocolData.cod_protocolo);

                    // Verifica e define o nome e documento do cliente ou representante
                    const isRepresentative = protocolData.nome_cliente !== protocolData.nome_representante;
                    const name = isRepresentative ? protocolData.nome_representante : protocolData.nome_cliente;
                    const document = isRepresentative ? protocolData.email_representante : protocolData.email_representante;

                    {console.log(protocolData)}
                    setClientName(name);
                    setClientDocument(document);

                    // Define os rótulos
                    setLabelName(isRepresentative ? 'Nombre del representante' : 'Nombre del cliente');
                    setLabelDocument(isRepresentative ? 'E-mail del representante' : 'E-mail del cliente');
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        setProtocolStatus('NotFound');
                    } else if (error.response && error.response.status !== 404) {
                        console.error('Error al buscar número de caso:', error);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleAvancarClick = () => {
        if (protocolNumber && protocolStatus === 'Aberto') {
            navigate('/protocolo', { state: { protocolId, codFarmacia } });
        }
    };

    const handleCancelarClick = () => {
        setProtocolNumber('');
        setProtocolId('');
        setProtocolStatus(null);
        setClientName('');
        setClientDocument('');
        setIsLoading(false);
    };

    const renderProtocolStatus = () => {
        if (protocolStatus === 'Aberto') {
            return (
                <div className="row protocol-details">
                    <p className="warning">
                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                        Confirme los datos antes de cambiar el dispositivo
                    </p>
                    <br />
                    <div>
                        <strong>{labelDocument}</strong>
                        <span className="document">{clientDocument}</span>
                    </div>
                    <br />
                    <div>
                        <strong>{labelName}</strong>
                        <span className="withdrawer-name">{clientName}</span>
                    </div>
                </div>
            );
        } else if (protocolStatus === 'Finalizado') {
            return (
                <div id="error-complete" className="alert alert-warning2" role="alert">
                    <p><span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> <strong>Atención</strong></p>
                    <p>Este número de caso ya ha sido finalizado y no está disponible para el rescate.</p>
                    <p>Si el cliente tiene dudas, pida que se comunique con el 800-802-226.</p>
                </div>
            );
        } else if (protocolStatus === 'Expirado') {
            return (
                <div id="error-complete" className="alert alert-warning2" role="alert">
                    <p><span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> <strong>Atención</strong></p>
                    <p>El número de caso está expirado y no está disponible para el rescate.</p>
                    <p>Si el cliente tiene dudas, pida que se comunique con el 800-802-226.</p>
                </div>
            );
        } else if (protocolStatus === 'NotFound') {
            return (
                <div id="error-not-found" className="alert alert-warning2" role="alert">
                    <p><span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> <strong>Atención</strong></p>
                    <p>El número de caso ha sido cerrado y/o los datos informados son divergentes de los informados en la atención.</p>
                    <p>Verifique la información ingresada y si el error persiste, solicite al cliente que se comunique con el 800-802-226.</p>
                </div>
            );
        }
        return null;
    };

    const isButtonDisabled = () => {
        return !protocolNumber || isLoading || protocolStatus === 'Finalizado' || protocolStatus === 'NotFound';
    };

    return (
        <>
            <InformacoesContainer>
                <div className="page-content start-protocol">
                    <h2>Información del número de caso</h2>
                    <br />
                    <div className='protocolo-div'>
                        <div>
                            <strong>Número de caso</strong>
                            <input
                                type="text"
                                className='form-control'
                                maxLength="8"
                                placeholder="000000000"
                                value={protocolNumber}
                                onChange={handleProtocolNumberChange}
                                onBlur={handleProtocolNumberBlur}
                                disabled={isLoading}
                            />
                        </div>
                        <div className='alert-div'>
                            {renderProtocolStatus()}
                        </div>
                        <div className='buttons-div'>
                            <button className='button-secondary' onClick={handleCancelarClick}>Cancelar</button>
                            <button
                                className={`button ${isButtonDisabled() ? 'disabled' : ''}`}
                                onClick={handleAvancarClick}
                                disabled={isButtonDisabled()}
                            >
                                Avanzar
                            </button>
                        </div>
                    </div>
                </div>
            </InformacoesContainer>
        </>
    );
}
