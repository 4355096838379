import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FooterContainer } from './Footer.styles';
import logout from '../Images/Navbar/logout-3-svgrepo-com.svg';

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogoutClick = () => {
        localStorage.clear(); // Limpa o localStorage
        navigate('/'); // Redireciona para a tela de login
    };

    return (
      <>
          <FooterContainer>
              <p></p>
              <p>Desarrollado por Foundever</p>
              
              <div
                  className={`rodape-logout ${location.pathname === '/' ? 'hidden' : ''}`}
                  onClick={handleLogoutClick}
              >
                  <img 
                      src={logout} 
                      alt="logout" 
                      className='footer-logout' 
                  />
                  <p>Salir</p>
              </div>
          </FooterContainer>
      </>
  );
}